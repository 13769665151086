import "./splash-screen.css";

const SplashScreen = () => {
  return (
    <>
      <div className="splash-box">
        
      </div>
    </>
  );
};
export default SplashScreen;
