import { ReactNode } from "react";
//import { LoginHeader } from "../component/login-header";
import { LoginFooter } from "../component/login-footer";

interface Props {
  children?: ReactNode;
}
const AnonymousLayout = ({ children, ...props }: Props) => {
  return (
    <div className="login-wrapper">
      {/* <LoginHeader></LoginHeader> */}
      <main className="main-content  mt-0">
        <div className="page-header align-items-start min-vh-100">
          {children}
        </div>
        <LoginFooter></LoginFooter>
      </main>
    </div>
  );
};

export default AnonymousLayout;
