import { ReactNode } from "react";
//import { LoginHeader } from "../component/login-header";

interface Props {
  children?: ReactNode;
}
const SignupLayout = ({ children, ...props }: Props) => {
  return (
    <>
      {/* <LoginHeader></LoginHeader> */}
      {children}
    </>
  );
};

export default SignupLayout;
